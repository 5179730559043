const animateCard = () => {
    const controller = new ScrollMagic.Controller();
    $('.descr-row').each(function () {
        const tlScroll = new TimelineMax();
        const img = $(this).find('.descr__img-wrap');
        // const elHeight = $(this).outerHeight();
        if (!$(this).hasClass('reverse')) {
            tlScroll
                .to(img, 0.6, {
                    top: '-17%',
                    left: '-14%',
                    ease: Power0.easeNone,
                });
            new ScrollMagic.Scene({
                    triggerElement: this,
                    triggerHook: 0.5,
                })
                .setTween(tlScroll)
                .addTo(controller);
        } else {
            tlScroll
                .to(img, 0.5, {
                    top: '-17%',
                    left: '14%',
                    ease: Power0.easeNone,
                });
            const scene = new ScrollMagic.Scene({
                    triggerElement: this,
                    triggerHook: 0.5,
                })
                .setTween(tlScroll)
                // .addIndicators()
                .addTo(controller);
        }
    });
};
animateCard();

const reviewSlider = function initProductSlider() {
    const slider = $('.js-review-slider');
    const img = $('.js-review-img div');

    $(slider).on({
        afterChange: (event, slick, currentSlide) => {
            $('.benefit__item').removeClass('is-active');
            $('.benefit__item').eq(currentSlide).addClass('is-active');
            $(img).hide();
            $(img).eq(currentSlide).show(0);
        },
    }).slick({
        fade: true,
        infinite: true,
        slideToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 300,
        appendArrows: $('.review__arrow'),
    });

    $('.benefit__item').click((e) => {
        $(slider).slick('slickPause');
        const index = $('.benefit__item').index(e.currentTarget);
        $(slider).finish().slick('slickGoTo', index);
        $('.benefit__item').removeClass('is-active');
        $(this).addClass('is-active');
        $(img).hide();
        $(img).eq(index).show();
    });
};
reviewSlider();

const animateGreetScreen = () => {
    const controller = new ScrollMagic.Controller();
    const tl = new TimelineMax();
    const tlHeader = new TimelineMax();
    const tlText = new TimelineMax();
    const maskHeader = $('.greet__header');
    const maskText = $('.greet__text');
    const maskScroll = $('.greet .scroll');
    const w = $(window).outerWidth();
    let maskX = '-140%';
    let maskY = '-150%';
    let maskScale = 23;
    let doctorWidth = '13%';
    let zIndex = 0;
    const vh = (coef) => window.innerHeight * (coef / 100);
    if (w < 430) {
        maskX = '-150%';
        maskY = '-0%';
        maskScale = 25;
        doctorWidth = '37%';
        zIndex = 1;
    }

    tl
        .to('.greet__mask', 3, {
            scaleX: maskScale,
            scaleY: maskScale,
            x: maskX,
            y: maskY,
            ease: Power0.easeNone,
        })
        .to('.hero__bg-doctor', 3, {
            x: '0%',
            y: '0%',
            width: doctorWidth,
            ease: Power0.easeNone,
        }, '-=1.6')
        .set('.hero__bg-doctor', {
            css: {
                zIndex: zIndex
            },
            ease: Power0.easeNone,
        })
        .set($('.greet'), {
            autoAlpha: 0,
        })
        .to('.hero .title', 0.5, {
            y: 0,
            autoAlpha: 1,
            ease: Power1.ease0ut,
        }, '-=0.3')
        .to('.hero p', 0.5, {
            y: 0,
            autoAlpha: 1,
            ease: Power1.ease0ut,
        }, '-=0.3')


    new ScrollMagic.Scene({
            triggerElement: '#trigger',
            triggerHook: 0,
            duration: '200%',
        })
        .setTween(tl)
        .setPin('.hero', {
            pushfollowers: false,
        })
        .addTo(controller);


    tlHeader
        .to(maskHeader, 1, {
            autoAlpha: 0,
            y: '-30%',
        });
    new ScrollMagic.Scene({
            triggerElement: '#trigger',
            triggerHook: 0,
            duration: '30%',
        })
        .setTween(tlHeader)
        .addTo(controller);

    tlText
        .to(maskText, 1, {
            autoAlpha: 0,
            y: '100%',
        })
        .to(maskScroll, 1, {
            autoAlpha: 0,
            y: '100%',
        }, '-=1');
    new ScrollMagic.Scene({
            triggerElement: '#trigger',
            triggerHook: 0,
            duration: '30%',
        })
        .setTween(tlText)
        .offset('0')
        .addTo(controller);
};
animateGreetScreen();

const animateSite = () => {
    const controller = new ScrollMagic.Controller();
    const tl1 = new TimelineMax();
    const tl2 = new TimelineMax();
    const tl3 = new TimelineMax();
    const tl4 = new TimelineMax();
    const tl6 = new TimelineMax();
    const tl7 = new TimelineMax();
    const tl8 = new TimelineMax();
    const tl9 = new TimelineMax();

    tl1
        .fromTo('.can-do .can-do__img', 0.5, {
            autoAlpha: 0,
            y: '10%',
            x: '7%',
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
            y: '0%',
            x: '7%',
            ease: Power0.easeNone,
        }, 0.1);
    new ScrollMagic.Scene({
            triggerElement: '.can-do',
            triggerHook: 0.5,
            duration: '30%',
        })
        .setTween(tl1)
        .addTo(controller);
    tl2
        .staggerFromTo('.can-do__list-item', 0.8, {
            autoAlpha: 0,
            y: '30%',
            ease: Power0.easeNone,
        }, {
            autoAlpha: 1,
            y: '0%',
            ease: Power0.easeNone,
        }, 0.1);
    new ScrollMagic.Scene({
            triggerElement: '.can-do',
            triggerHook: 0.2,
            duration: '30%',
        })
        .setTween(tl2)
        .addTo(controller);


    $('.line').each(function () {
        const tlLine = new TimelineLite();
        const el = $(this);
        tlLine
            .to(el, 1, {
                y: '40%',
                // opacity: 0,
                ease: Power0.easeNone,
            });
        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.9,
                duration: '100%',
            })
            .setTween(tlLine)
            .addTo(controller);
    });

    tl3 
        // animate the linear gradient
        .staggerTo('.descr__top-img svg stop', 1, {

            cycle: {
                stopColor: ['#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50'],
            },
        }, 0.1, 0);
    new ScrollMagic.Scene({
            triggerElement: '.descr__top-img',
            triggerHook: 0.3,
            duration: '100%',
        })
        .setTween(tl3)
        .addTo(controller);


    tl4
        .staggerFromTo('.how-use__row div', 1, {
            cycle: {
                y: [50, -50],
            },
            // y: 50,
            opacity: 0,
            ease: Power0.easeNone,
        }, {
            y: 0,
            opacity: 1,
            ease: Power0.easeNone,
        }, 0.01);

    new ScrollMagic.Scene({
            triggerElement: '.how-use',
            triggerHook: 0.6,
            duration: '50%',
        })
        .setTween(tl4)
        .addTo(controller);
    tl6
        .staggerFromTo('.inside__item', 1, {
            // cycle:{y:[50,-50]},
            y: 30,
            autoAlpha: 0,
            ease: Power0.easeNone,
        }, {
            y: 0,
            autoAlpha: 1,
            ease: Power0.easeNone,
        }, 0.2);

    new ScrollMagic.Scene({
            triggerElement: '.inside__items',
            triggerHook: 0.7,
            duration: '30%',
        })
        .setTween(tl6)
        .addTo(controller);

    $('.test__item').each(function () {
        const tl = new TimelineLite();
        const tl1 = new TimelineLite();
        const tl2 = new TimelineLite();
        const el = $(this);
        const title = el.find('.test__item-content .test__title');
        const text = el.find('.test__item-content p');
        const img = el.find('.test__item-img');

        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.8,
                duration: '40%',
            })
            .setTween(tl.fromTo(title, 1, {
                y: '10%',
                opacity: 0,
                ease: Power0.easeNone,
            }, {
                y: '0%',
                opacity: 1,
                ease: Power0.easeNone,
            }))
            .addTo(controller);
        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.7,
                duration: '40%',
            })
            .setTween(tl1.fromTo(text, 1, {
                y: '20%',
                opacity: 0,
                ease: Power0.easeNone,
            }, {
                y: '0%',
                opacity: 1,
                ease: Power0.easeNone,
            }))
            .addTo(controller);
        new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.7,
                duration: '40%',
            })
            .setTween(tl2.fromTo(img, 1, {
                y: '20%',
                opacity: 0,
                ease: Power0.easeNone,
            }, {
                y: '0%',
                opacity: 1,
                ease: Power0.easeNone,
            }))
            .addTo(controller);
    });

    tl7
        .staggerTo('.footer svg stop', 1, {
            cycle: {
                stopColor: ['#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50'],
            },
        }, 0.1, 0);
    new ScrollMagic.Scene({
            triggerElement: '.footer',
            triggerHook: 0.5,
            duration: '100%',
        })
        .setTween(tl7)
        .addTo(controller);
    tl8
        .to('.footer__img1', 1, {
            y: 0,
        });
    new ScrollMagic.Scene({
            triggerElement: '.footer',
            triggerHook: 0.3,
            duration: '100%',
        })
        .setTween(tl8)
        // .addIndicators()
        .addTo(controller);
    tl9
        .to('.footer__img3', 1, {
            y: 0,
        });
    new ScrollMagic.Scene({
            triggerElement: '.footer__img2',
            triggerHook: 0.5,
            // offset: '50%'
            duration: '100%',
        })
        .setTween(tl9)
        .addTo(controller);
};
animateSite();